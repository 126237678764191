import * as React from "react"
import Layout from "../../components/layout"
import Servicelinks from "../../components/servicelinks"
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.3 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};


const Hosting = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="Hosting">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
          <h1><span className="revealblack">Private Server and Web Hosting</span></h1>
          <p><span className="revealblack">To get your website or web app up and running, you will require web hosting. It’s important to get some of the basic understanding of web hosting that includes managed dedicated server, VPS hosting (Virtual Private Server) and shared hosting.</span></p>
          <a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/tobstar-social-media-header.jpg'} alt="Private Server Web Hosting" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">

        <h2>What is private server and web hosting? What is difference?</h2>
<p>FYI <a href="https://www.websitebuilderexpert.com/web-hosting/comparisons/shared-hosting-vs-vps-hosting/" target="_blank" rel="noreferrer">https://www.websitebuilderexpert.com/web-hosting/comparisons/shared-hosting-vs-vps-hosting/</a></p>

<p>Shared hosting is the most popular web hosting for small websites. When your website is on a shared hosting, you’re sharing key resources like CPU, RAM, and hard drive space with other website owners using that same server.</p>

<p>Dedicated server is the opposite of shared hosting. Rather than pooling resources with other websites, you have one dedicated server that is reserved for your website only.</p>

<p>VPS hosting get the similar benefits of a dedicated server for an affordable price but there will be other websites hosted on the same hardware as yours. Your website will be the only domain allocated to your particular virtual compartment. You will get your own operating system, dedicated storage, powerful CPU, scalable RAM, and unlimited bandwidth.</p>

<p>We offer reasonable hosting solutions to clients with our shared and VPS hosting, which we use to manage web hosting on behalf of our clients. With personal and fast support this can provide some cost savings for web development and on-going maintenance and optimised for fast performance websites.</p>

<p>With our shared hosting and VPS hosting, we manage the physical dedicated server with Equinix Data Centre in Sydney, run with Litespeed Web server, Cloudlinux and 100% SSD-Powered Hosting with Firewall at both ends, Imunify360 Malware and Virus Scans, CloudLinux Security, Mod Security enabled.</p>

<p>Our server’s security and server maintenance is our vital, check our score of header security at <a href="https://securityheaders.com/?q=https%3A%2F%2Ftobstar.com.au&followRedirects=on" target="_blank" rel="noreferrer">https://securityheaders.com/?q=https%3A%2F%2Ftobstar.com.au&followRedirects=on</a></p>

<p>Please contact us to inquire for a hosting solution package to meet your website requirements and we also offer regular backup (daily) and restorations.</p>

<p>We also manage domain name registration and DNS records on behalf of clients and set up with Google Workspace or Office 365 for emails.</p>

      </div>
       <Servicelinks></Servicelinks>
   </Section>
</Layout>

  </>
  )
}

export default Hosting


export const Head = () => (
  <>
    <title>Private Server, VPS and Web Hosting - Tobstar</title>
<meta name="description" content="To get your website or web app up and running, you will require web hosting. It’s important to get some of the basic understanding of web hosting that includes managed dedicated server, VPS hosting and shared hosting." />
  </>
)